
import { ref, defineComponent, onMounted } from "vue";
import axios from "axios";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default defineComponent({
  name: "GetTweet",
  props: {
    unit: String,
  },
  setup(props) {
    const twitter_data = ref([]);
    function getData() {
      axios
        .get(
          `https://${process.env.VUE_APP_API_BASE}/twitter?slug_name=${props.unit}`
        )
        .then((response) => {
          for (const i of response.data.items) {
            i.url = "https://twitter.com/seigo2018/timelines/" + i.id;
          }
          twitter_data.value = response.data.items;
        });
    }
    onMounted(() => {
      let tweetScript = document.createElement("script");
      tweetScript.setAttribute(
        "src",
        "https://platform.twitter.com/widgets.js"
      );
      document.head.appendChild(tweetScript);
      getData();
    });

    return {
      twitter_data,
      height: window.innerHeight * 0.8,
    };
  },
});
