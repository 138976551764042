
import { ref, defineComponent, onMounted } from "vue";
import axios from "axios";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default defineComponent({
  name: "GetInfo",
  props: {
    unit: String,
  },
  setup(props) {
    const info_data = ref([]);
    const unit_data = ref([]);
    function getData() {
      axios
        .get(
          `https://${process.env.VUE_APP_API_BASE}/info?slug_name=${props.unit}`
        )
        .then((response) => {
          info_data.value = response.data.items;
        });
      axios
        .get(
          `https://${process.env.VUE_APP_API_BASE}/units?slug_name=${props.unit}`
        )
        .then((response) => {
          unit_data.value = response.data.items[0].other_data;
        });
    }
    onMounted(() => {
      getData();
    });
    return {
      info_data,
      unit_data,
    };
  },
});
