
import { ref, defineComponent, onMounted } from "vue";
import axios from "axios";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default defineComponent({
  name: "Announce",
  props: {
    announce: String,
  },
  setup(props) {
    const announce_data = ref("");
    const breaks = true;
    onMounted(async () => {
      axios
        .get(
          `https://${process.env.VUE_APP_STRAPI_BASE}/articles?id=${props.announce}`
        )
        .then((response) => {
          for (const i of response.data) {
            let d = new Date(i.published_at);
            let publishedDateStr = `
              ${d.getFullYear()}/${d.getMonth() + 1}/${d.getDate()}`.replace(
              /\n|\r/g,
              ""
            );
            i.published_at = publishedDateStr;
          }
          announce_data.value = response.data[0].Content;
        });
    });
    return {
      announce_data,
      breaks,
    };
  },
});
