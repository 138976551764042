
import { defineComponent, ref } from "vue";
import { useCookie } from "vue-cookie-next";
export default defineComponent({
  name: "RulePopup",
  setup() {
    const cookie = useCookie();
    const isVisited = cookie.getCookie("visited");
    const isPopupVisible = ref(true);

    const toggleModal = () => {
      isPopupVisible.value = false;
      cookie.setCookie("visited", "true", { expire: "1y" });
    };
    if (isVisited === "true") {
      isPopupVisible.value = false;
    }
    return { isPopupVisible, toggleModal };
  },
});
