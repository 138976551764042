
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
export default defineComponent({
  setup() {
    const router = useRouter();
    const search = (query: string) => {
      axios
        .get(
          `https://${
            process.env.VUE_APP_API_BASE
          }/units?slug_name=${encodeURIComponent(query)}`
        )
        .then((response) => {
          if (response.status === 204) {
            router.replace("/notfound");
          } else if (response.status === 200) {
            console.log(response.data.items);
            let path = response.data.items[0].url_name;
            router.push("/unit/" + path);
          }
        });
    };
    let query = "";
    return {
      query,
      search,
    };
  },
});
