<template>
  <div class="about container w-4/5 m-auto">
    <h1 class="px-5 text-4xl">About</h1>
    <div
      class="
        container
        my-5
        px-5
        pb-3
        border-2 border-fuchsia-600
        leading-relaxed
      "
    >
      <h2 class="underline">このサイトについて</h2>
      <div class="">
        <p class="text-lg">
          当サービスは，にじさんじ内のユニットや CP
          の最新情報を提供するものです。
        </p>
        <p>
          にじさんじ非公式wikiのような，エピソードなどを含むデータベースではなく，ツイートやYoutubeの切り抜きなどのリアルタイムな情報をお届けすることを目的としています。
        </p>
        <p class="text-lg">現在の以下の情報を掲載しています。</p>
        <ul class="list-disc list-inside mt-3">
          <li>CP・ユニットが含まれている公式のお知らせ</li>
          <li>PixivやTwitterでのタグ情報</li>
          <li>YouTube上の(切り抜きを含む)関連動画</li>
          <li>ユニット・CPのメンバーの最新のツイート</li>
          <li>ユニット・CPで検索した最新のツイート</li>
        </ul>
      </div>
    </div>
    <div class="container my-5 px-5 pb-3 mx-auto border-2 border-fuchsia-600">
      <h2 class="underline">運営方針について</h2>
      <div>
        <p>
          ユニット・CPの要望は<a
            class="underline"
            href="https://docs.google.com/forms/d/1uJXGWb26dRr35JOcftKZH1W4szay4-zecmn_PVYfk0c/viewform"
            target="_blank"
            rel="noopener noreferrer"
            >こちらのフォーム</a
          >より随時受け付けております。
        </p>
        <p>
          ユニット・CPの追加，更新について詳しくは<a
            href="/guideline"
            class="underline"
            >こちら</a
          >を御覧ください。
        </p>
      </div>
    </div>
    <div class="container my-5 px-5 pb-3 mx-auto border-2 border-fuchsia-600">
      <h2 class="underline">利用規約・注意事項</h2>
      <p>
        <a href="/terms" class="underline">利用規約</a>
      </p>
      <p>
        <a href="/guideline" class="underline">ガイドライン</a>
      </p>
      <p>
        <a href="/privacy" class="underline">プライバシーポリシー</a>
      </p>
    </div>
  </div>
</template>
