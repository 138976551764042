
import { defineComponent, ref } from "vue";
import RulePopup from "@/components/RulePopup.vue";
export default defineComponent({
  components: { RulePopup },
  setup() {
    const units = ref([]);
    const url = new URL(`https://${process.env.VUE_APP_API_BASE}/units`);
    fetch(url.toString())
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        for (const i of response.items) {
          i.url = "/unit/" + i.url_name;
        }
        units.value = response.items;
      });
    return { units, RulePopup };
  },
});
