
import { ref, defineComponent, onMounted } from "vue";
import axios from "axios";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
export default defineComponent({
  name: "VideoContainer",
  props: {
    unit: String,
  },
  setup(props) {
    const video_data = ref([]);
    function getData() {
      axios
        .get(
          `https://${process.env.VUE_APP_API_BASE}/youtube?slug_name=${props.unit}`
        )
        .then((response) => {
          for (const i of response.data.items) {
            i.url = "https://youtu.be/" + i.video_id;
          }
          video_data.value = response.data.items;
        });
    }

    onMounted(() => {
      getData();
    });
    return {
      video_data,
    };
  },
});
