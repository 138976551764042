import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-left break-words" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue3_markdown_it = _resolveComponent("vue3-markdown-it")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_vue3_markdown_it, {
      class: "",
      breaks: _ctx.breaks,
      source: _ctx.announce_data
    }, null, 8, ["breaks", "source"])
  ]))
}