
import { defineComponent } from "vue";
import source from "@/assets/terms.md";
const breaks = true;
export default defineComponent({
  setup() {
    return {
      source,
      breaks,
    };
  },
});
