
import { ref, defineComponent, onMounted } from "vue";
import axios from "axios";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default defineComponent({
  name: "GetTweet",
  props: {
    unit: String,
  },
  setup(props) {
    const news_data = ref([]);
    function getData() {
      axios
        .get(
          `https://${process.env.VUE_APP_API_BASE}/news?slug_name=${props.unit}`
        )
        .then((response) => {
          for (const i of response.data.items) {
            i.url = "https://www.nijisanji.jp/news/" + i.id;
          }
          news_data.value = response.data.items;
        });
    }
    onMounted(() => {
      getData();
    });
    return {
      news_data,
    };
  },
});
