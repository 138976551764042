import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./index.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSearch,
  faHome,
  faBullhorn,
  faUsers,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Vue3MarkdownIt from "vue3-markdown-it";
import { VueCookieNext } from "vue-cookie-next";
import "@/assets/tailwind.css";
library.add(faSearch, faHome, faBullhorn, faUsers, faInfoCircle);
library.add(faTwitter, faYoutube);

const app = createApp(App);

app.use(Vue3MarkdownIt);
app.use(router);
app.component("fa", FontAwesomeIcon);
app.use(VueCookieNext);
app.mount("#app");
