
import GetTweet from "@/components/Tweet.vue";
import VideoContainer from "@/components/VideoContainer.vue";
import News from "@/components/UnitNews.vue";
import { onMounted, defineComponent, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "axios";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
import RulePopup from "@/components/RulePopup.vue";
import Info from "@/components/UnitInfo.vue";
export default defineComponent({
  components: {
    RulePopup,
    GetTweet,
    VideoContainer,
    News,
    Info,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      unitSlug: "",
      size: "",
      openTab: 1,
      unitName: "",
    });
    state.size = "lg";
    state.unitSlug = route.params.unit.toString();
    onMounted(async () => {
      axios
        .get(
          `https://${process.env.VUE_APP_API_BASE}/units?slug_name=${route.params.unit}`
        )
        .then((response) => {
          for (const i of response.data.items) {
            i.url = "https://twitter.com/seigo2018/timelines/" + i.id;
          }

          if (response.status === 204) {
            router.replace("/404");
          } else if (response.status === 200) {
            state.unitName = response.data.items[0].original_name;
          }
        });
    });
    const toggleTabs = (tabNumber: number) => {
      state.openTab = tabNumber;
    };
    return {
      GetTweet,
      VideoContainer,
      News,
      RulePopup,
      Info,
      state,
      toggleTabs,
    };
  },
});
