
import { defineComponent, reactive } from "vue";
import Announce from "@/components/Announce.vue";
import { useRoute } from "vue-router";
export default defineComponent({
  components: {
    Announce,
  },

  setup() {
    const route = useRoute();
    const state = reactive({
      id: "",
    });
    state.id = String(route.params.id);
    return { id: state.id };
  },
});
