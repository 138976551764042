import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container mx-auto w-4/5 border-2 border-fuchsia-600" }
const _hoisted_2 = { class: "m-7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue3_markdown_it = _resolveComponent("vue3-markdown-it")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_vue3_markdown_it, {
        breaks: _ctx.breaks,
        source: _ctx.source
      }, null, 8, ["breaks", "source"])
    ])
  ]))
}