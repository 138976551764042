import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import Unit from "../views/Unit.vue";
import UnitAll from "../views/UnitAll.vue";
import Announce from "../views/Announce.vue";
import AnnounceAll from "../views/AnnounceAll.vue";
import Terms from "../views/Terms.vue";
import ErrorPage from "../views/ErrorPage.vue";
import Guideline from "../views/Guideline.vue";
import NotFound from "../views/NotFound.vue";
import Privacy from "../views/Privacy.vue";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/unit/:unit",
    name: "Unit",
    component: Unit,
    props: true,
  },
  {
    path: "/unit",
    name: "UnitAll",
    component: UnitAll,
  },
  {
    path: "/news",
    name: "AnnounceAll",
    component: AnnounceAll,
  },
  {
    path: "/news/:id",
    name: "Announce",
    component: Announce,
  },
  {
    path: "/notfound",
    name: "notFound",
    component: NotFound,
  },
  {
    path: "/terms",
    name: "Terms",
    component: Terms,
  },
  {
    path: "/guideline",
    name: "Guideline",
    component: Guideline,
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
  },
  {
    path: "/:catchAll(.*)|(?!*.png)",
    name: "Error",
    component: ErrorPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
