import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container p-4 mx-auto w-4/5 xl:w-2/3 border-2 border-fuchsia-600" }
const _hoisted_2 = { class: "my-8 border-b-2 border-fuchsia-600 pb-3 mx-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Announce = _resolveComponent("Announce", true)!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Announce, { announce: _ctx.id }, null, 8, ["announce"])
    ])
  ]))
}