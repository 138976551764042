
import { defineComponent, ref } from "vue";
import RulePopup from "@/components/RulePopup.vue";
import axios from "axios";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
export default defineComponent({
  components: { RulePopup },
  setup() {
    const announce_data = ref([]);
    function getAnnounce() {
      axios
        .get(`https://${process.env.VUE_APP_STRAPI_BASE}/articles`)
        .then((response) => {
          for (const i of response.data) {
            i.url = `/news/${i.id}`;
            let d = new Date(i.published_at);
            let publishedDateStr = `
              ${d.getFullYear()}/${d.getMonth() + 1}/${d.getDate()}`.replace(
              /\n|\r/g,
              ""
            );
            i.published_at = publishedDateStr;
          }
          announce_data.value = response.data;
        });
    }
    const units = ref([]);
    const url = new URL(`https://${process.env.VUE_APP_API_BASE}/units`);
    fetch(url.toString())
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        for (const i of response.items) {
          i.url = "/unit/" + i.url_name;
        }
        units.value = response.items;
      });
    getAnnounce();
    return { units, RulePopup, announce_data };
  },
});
